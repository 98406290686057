import React, { useEffect } from 'react'
import { getUserSessionColor, getUserSessionEmoji, User } from '../models/User'
import { getRandomColor, getRandomUserEmoji } from '../util'

export interface UserContextValue {
  user?: User
  setUser: (user: User) => void
}

const UserContext = React.createContext<UserContextValue>({
  setUser: () => {},
})

export const useUser = () => {
  const { user } = React.useContext(UserContext)
  return user
}

const random = true

export const useUserContext = (authData: any) => {
  const [user, setUser] = React.useState<User | undefined>()
  const userContext = { user, setUser }

  useEffect(() => {
    if (authData?.attributes) {
      const newUser: User = {
        username: authData.username,
        ...authData.attributes,
        sessionColor: random
          ? getRandomColor()
          : getUserSessionColor(authData.username ?? authData.attributes.email),
        emoji: random
          ? getRandomUserEmoji()
          : getUserSessionEmoji(authData.username ?? authData.attributes.email),
      }
      setUser(newUser)
      console.log(authData)
    }
  }, [authData])

  return userContext
}

export const UserContextProvider = UserContext.Provider

export default UserContext
