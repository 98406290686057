import React from 'react'
import { User } from '../models/User'
import Avatar from './Avatar'

export interface AvatarsProps {
  users: User[]
}

const numToShow = 3
const avatarWidth = 32
const offset = 16

export default function Avatars({ users }: AvatarsProps) {
  const userCount = users.length
  const moreUsers = userCount > numToShow
  const moreUsersCount = userCount - numToShow
  const width = calcWidth(userCount, moreUsers)
  return (
    <div style={{ position: 'relative', minWidth: width, height: avatarWidth }}>
      {users.slice(0, numToShow).map(
        (user, index) =>
          user && (
            <Avatar
              key={user.sub}
              color={user.sessionColor}
              emoji={user.emoji}
              style={{
                left: index * offset,
                position: 'absolute',
                zIndex: 10 - index,
              }}
            />
          )
      )}
      {moreUsers && `and ${moreUsersCount} more`}
    </div>
  )
}

const calcWidth = (count: number, more: boolean) => {
  if (more) {
    return 256
  }
  if (count === 1) {
    return avatarWidth
  }
  return avatarWidth + (count - 1) * offset
}
