import { useMyPresence, useOthers } from '@liveblocks/react'
import React, { useEffect, useState } from 'react'
import { useUser } from '../common/UserContext'
import { User } from '../models/User'
import Cursor from './Cursor'

type Presence = {
  cursor: {
    x: number
    y: number
    xx: number
    yy: number
  } | null
  message?: string
  user: User
}

export enum CursorMode {
  Hidden,
  Normal,
  Chat,
}

export type CursorState = {
  mode: CursorMode
  message?: string
  previousMessage?: string | null
}

export default function CursorPresence({ children }: any) {
  const [myPresence, updateMyPresence] = useMyPresence<Presence>()
  const user = useUser()
  const [state, setState] = useState<CursorState>({ mode: CursorMode.Hidden })

  useEffect(() => {
    updateMyPresence({
      user,
    })
  }, [user])

  const onPointerMove = (event: any) => {
    if (state?.message) {
      setState(prev => ({ ...prev, mode: CursorMode.Chat }))
    }
    if (event.detail) {
      updateMyPresence({
        cursor: {
          x: event.detail.clientX,
          y: event.detail.clientY,
          xx: Math.round(event.detail.clientX),
          yy: Math.round(event.detail.clientY),
        },
      })
    } else {
      updateMyPresence({
        cursor: {
          x: event.pageX / window.innerWidth,
          y: event.pageY / window.innerHeight,
          xx: Math.round(event.clientX),
          yy: Math.round(event.clientY),
        },
      })
    }
  }

  const onPointerLeave = () => {
    // setState({ message: "", mode: CursorMode.Hidden });
    // updateMyPresence({ cursor: null, message: "" });
  }

  useEffect(() => {
    function onKeyUp(e: KeyboardEvent) {
      if (e.key === '/') {
        setState({ mode: CursorMode.Chat, message: '' })
      } else if (e.key === 'Escape') {
        updateMyPresence({ message: '' })
        setState({ mode: CursorMode.Hidden })
      } else if (e.key === 'e') {
        // setState({ mode: CursorMode.ReactionSelector });
      }
    }

    window.addEventListener('keyup', onKeyUp)

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === '/') {
        e.preventDefault()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keyup', onKeyUp)
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [updateMyPresence])

  const others = useOthers<Presence>()
  const renderCursor = ({
    connectionId,
    presence,
  }: {
    presence?: Presence
    connectionId: number
  }) => {
    if (!presence?.cursor) {
      return null
    }
    // TODO: something better for this check
    const isMine = connectionId === 9999
    const { x, y, xx, yy } = presence.cursor
    const { user, message } = presence
    const emoji = user?.emoji ?? ''
    const normalizedX = isMine ? xx : Math.round(x * window.innerWidth)
    const normalizedY = isMine ? yy : Math.round(y * window.innerHeight)

    return (
      <Cursor
        mode={state.mode}
        mine={isMine}
        key={connectionId}
        color={user?.sessionColor}
        emoji={emoji}
        onChange={({
          message,
          previousMessage,
          mode,
        }: {
          message?: string
          previousMessage?: string | null
          mode?: CursorMode
        }) => {
          setState({ message, previousMessage, mode } as CursorState)
          updateMyPresence({
            message: previousMessage || message,
          })
        }}
        x={normalizedX}
        y={normalizedY}
        message={isMine ? state?.message : message}
        previousMessage={state?.previousMessage}
      />
    )
  }
  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        cursor: state.mode === CursorMode.Chat ? 'none' : 'url(images/cursor.svg) 0 0, auto',
      }}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerLeave}>
      {myPresence.cursor &&
        state.mode === CursorMode.Chat &&
        renderCursor({ presence: myPresence, connectionId: 9999 })}
      {others.map(other =>
        renderCursor({
          connectionId: other.connectionId,
          presence: other.presence,
        })
      )}
      {children}
    </div>
  )
}
