import styled from 'styled-components/macro'

interface StyleProps {
  spacing?: number
}

const Row = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
  ${props =>
    props.spacing ? `margin: ${-props.spacing}px; & > * { margin: ${props.spacing}px; }` : ''}
`

export default Row
