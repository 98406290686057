import React, { useEffect } from 'react'
import './App.css'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import Header from './components/Header'
import Page from './components/Page'
import Room from './components/Room'
import awsconfig from './aws-exports'
import ContextProviders from './common/ContextProviders'
import '@aws-amplify/ui/dist/style.css'

Amplify.configure(awsconfig)

const App = function ({ authData }: any) {
  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current
      const handleMouseMove = (event: any) => {
        const clRect = iframe?.getBoundingClientRect()
        const x =
          (event.pageX + clRect.left) / (iframe?.contentWindow?.document?.body?.scrollWidth ?? 1)
        const y =
          (event.pageY + clRect.top) / (iframe?.contentWindow?.document?.body?.scrollHeight ?? 1)
        const evt = new CustomEvent<Partial<PointerEvent>>('pointermove', {
          bubbles: true,
          cancelable: false,
          detail: {
            clientX: x, // + clRect.left,
            clientY: y, // + clRect.top,
          },
        })

        iframe.dispatchEvent(evt)
      }
      const handleKeyup = (event: any) => {
        iframe.dispatchEvent(event)
      }
      iframe.contentWindow?.addEventListener('keyup', handleKeyup, {
        passive: true,
      })
      iframe.contentWindow?.addEventListener('pointermove', handleMouseMove, {
        passive: true,
      })
      return () => {
        iframe.contentWindow?.removeEventListener('pointermove', handleMouseMove)
        iframe.contentWindow?.removeEventListener('keyup', handleKeyup)
      }
    }
  }, [])

  const src = window.location.href

  return (
    <ContextProviders authData={authData}>
      <Room roomId={src}>
        <div className='App'>
          <Header />
          <Page ref={iframeRef} title={document.title} src={src} />
        </div>
      </Room>
    </ContextProviders>
  )
}

export default withAuthenticator(App)
