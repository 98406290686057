import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'
import CursorInput, { CursorInputProps } from './CursorInput'
import CursorMessage from './CursorMessage'
import { CursorMode } from './CursorPresence'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 20px);
  display: flex;
  flex-direction: column;
  gap: 8px;
`

interface StyledContainerProps {
  color?: string
  emoji?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  border-radius: 20px;
  position: relative;
  background-color: ${props => props?.color ?? '#ccc'};
  padding-left: ${props => (props.emoji ? 24 : 0)}px;
`

const AltCursorImage = function () {
  return (
    <svg width={24} height={36} viewBox='0 0 24 36' fill='none'>
      <path
        d='M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z'
        fill='currentColor'
        stroke='white'
      />
    </svg>
  )
}

export interface CursorProps extends Pick<CursorInputProps, 'onChange'> {
  x: number
  y: number
  color: string
  emoji: string
  mine?: boolean
  mode: CursorMode
  message?: string
  previousMessage?: string | null
}

export default function Cursor({
  x = 0,
  y = 0,
  color,
  emoji,
  mine = false,
  mode,
  message,
  previousMessage,
  onChange,
}: CursorProps) {
  return (
    <div
      style={{
        color: mine ? 'black' : color,
        position: 'absolute',
        transform: `translateX(${x}px) translateY(${y}px)`,
        top: 0,
        left: 0,
        transition: 'transform 0.5s cubic-bezier(.17, .93, .38, 1)',
      }}>
      <AltCursorImage />
      <Container>
        {mine ? (
          <>
            {previousMessage && (
              <StyledContainer color={color}>
                <CursorMessage color={color} message={previousMessage} />
              </StyledContainer>
            )}
            {mode === CursorMode.Chat && (
              <StyledContainer color={color}>
                <CursorInput message={message} onChange={onChange} color={color} />
              </StyledContainer>
            )}
          </>
        ) : message ? (
          <StyledContainer emoji={Boolean(emoji)} color={color}>
            <div style={{ position: 'absolute', top: 2, left: 10 }}>{emoji}</div>
            {message && <CursorMessage message={message} />}
          </StyledContainer>
        ) : (
          <Avatar emoji={emoji} color={color} />
        )}
      </Container>
    </div>
  )
}
