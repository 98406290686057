import ReactDOM, { Renderer } from 'react-dom'
import { colors } from './theme/theme'
import { user as userEmoji } from './constants/emoji'

type RootElement = Parameters<Renderer>['0'][0]
type ContainerSelector = string

interface AppSetupConfig {
  rootElement: RootElement
  injectExtensionTo: ContainerSelector
  injectWebAppTo: ContainerSelector
}

const findElementInDOM = (selector: ContainerSelector) => document.querySelector(selector)

const renderAppToDOM = (element: RootElement, selector: ContainerSelector) => {
  ReactDOM.render(element, document.querySelector(selector))
}

const injectExtensionToDOM = (element: RootElement, selector: ContainerSelector) => {
  const rootElementId = 'root'

  const appContainer = document.createElement('div')
  appContainer.id = rootElementId

  const elementInDOM = findElementInDOM(selector)

  if (elementInDOM) {
    elementInDOM.append(appContainer)
    renderAppToDOM(element, `#${rootElementId}`)
  }
}

const hideBodyElements = (selector: ContainerSelector) => {
  const body = findElementInDOM(selector)

  const els = body?.getElementsByTagName('div')
  if (els) {
    els[0].style.display = 'none'
  }
}

const initExtension = (element: RootElement, selector: ContainerSelector) => {
  const interval = setInterval(() => {
    // Can't inject the extension to DOM.
    if (!findElementInDOM(selector)) {
      return
    }

    clearInterval(interval)
    hideBodyElements('body')
    injectExtensionToDOM(element, selector)
  }, 100)
} // check every 100ms

const setupProject = (config: AppSetupConfig) => {
  initExtension(config.rootElement, config.injectExtensionTo)
}

function hashCode(str: string) {
  let hash = 0
  if (str.length === 0) {
    return hash
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash &= hash // Convert to 32bit integer
  }
  return Math.abs(hash)
}

function getStableItem<T>(key: string, items: T[]): T {
  const hash = hashCode(key)
  return items[hash % items.length]
}
function getRandomItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)]
}
function getRandomColor() {
  return getRandomItem(colors)
}
function getRandomUserEmoji() {
  return getRandomItem(userEmoji)
}
function getStableColor(key: string) {
  return getStableItem(key, colors)
}
function getStableUserEmoji(key: string) {
  return getStableItem(key, userEmoji)
}

function hexToHSL(H: string, override?: { l?: string | number }) {
  // Convert hex to RGB first
  let r: any = 0
  let g: any = 0
  let b: any = 0
  if (H.length == 4) {
    r = `0x${H[1]}${H[1]}`
    g = `0x${H[2]}${H[2]}`
    b = `0x${H[3]}${H[3]}`
  } else if (H.length == 7) {
    r = `0x${H[1]}${H[2]}`
    g = `0x${H[3]}${H[4]}`
    b = `0x${H[5]}${H[6]}`
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta == 0) h = 0
  else if (cmax == r) h = ((g - b) / delta) % 6
  else if (cmax == g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return `hsl(${h},${s}%,${override?.l}` ?? `${l}%)`
}

export {
  setupProject,
  getRandomUserEmoji,
  getRandomColor,
  getStableColor,
  getStableUserEmoji,
  hashCode,
  hexToHSL,
}
