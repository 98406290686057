import { getStableColor, getStableUserEmoji } from '../util'

interface User {
  email: string
  username: string
  sub: string
  sessionColor: string
  emoji: string
}

const getUserSessionColor = (username: string) => getStableColor(username)

const getUserSessionEmoji = (username: string) => getStableUserEmoji(username)

export { getUserSessionColor, getUserSessionEmoji }
export type { User }
