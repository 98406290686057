const colors = [
  '#C00DFF',
  '#AB26FF',
  '#953FFF',
  '#8059FF',
  '#6B72FF',
  '#558BFF',
  '#40A4FF',
  '#2BBEFF',
  '#15D7FF',
  '#00F0FF',
  '#FFB800',
  '#E3BE1C',
  '#C6C439',
  '#AACB55',
  '#8ED171',
  '#71D78E',
  '#55DDAA',
  '#39E4C6',
  '#1CEAE3',
  '#00F0FF',
  '#FFB800',
  '#FDA402',
  '#FC8F04',
  '#FA7B07',
  '#F96609',
  '#F7520B',
  '#F63D0D',
  '#F42910',
  '#F31412',
  '#F10014',
]

export { colors }
