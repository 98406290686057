import React from 'react'
import { useUser } from '../common/UserContext'
import Row from '../ui/Row'
import Avatar from './Avatar'

export default function CurrentUser() {
  const user = useUser()

  return user ? (
    <Row spacing={4} style={{ alignItems: 'center' }}>
      <p>{user?.username}</p>
      <Avatar color={user.sessionColor} emoji={user.emoji} />
    </Row>
  ) : null
}
