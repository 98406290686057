import React from 'react'

export interface PageProps {
  title: string
  src: string
}

const Page = React.forwardRef<HTMLIFrameElement, PageProps>(
  ({ title, src }, ref) => (
    // true ? (
    <div
      className='page-contents'
      style={{
        backgroundImage: 'url(./images/background.svg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150vh',
        color: '#fafafa',
      }}>
      <div>
        <h1>Join the chaos</h1>
        <p>What a wonderful web</p>
      </div>
    </div>
  )
  // ) : (
  //   <iframe
  //     ref={ref}
  //     id='target-page'
  //     style={{
  //       width: '100vw',
  //       height: 'calc(100vh - 56px)',
  //       marginTop: 56,
  //       border: 'none',
  //     }}
  //     className='page-contents'
  //     title={title}
  //     src={src}
  //   />
  // )
)

export default Page
