import React from 'react'
import { UserContextProvider, useUserContext } from './UserContext'

export interface ContextProvidersProps {
  authData: any
}

const ContextProviders: React.FC<ContextProvidersProps> = function (props) {
  const { children, authData } = props
  const userContext = useUserContext(authData)
  return <UserContextProvider value={userContext}>{children}</UserContextProvider>
}

export default ContextProviders
