import { RoomProvider } from '@liveblocks/react'
import React from 'react'
import CursorPresence from './CursorPresence'

export interface RoomProps {
  roomId: string
  children: React.ReactNode
}

export default function Room({ children, roomId }: RoomProps) {
  const defaultPresence = () => ({
    cursor: null,
    message: '',
  })

  // Creating a room
  return (
    <RoomProvider id={roomId} defaultPresence={defaultPresence}>
      <CursorPresence>{children}</CursorPresence>
    </RoomProvider>
  )
}
