import React from 'react'
import styled from 'styled-components/macro'

const StyledText = styled.div`
  width: max-content;
  white-space: break-spaces;
  position: relative;
  word-break: break-word;
  min-height: 24px;
  max-width: 256px;
  padding: 4px 12px;
  color: #111;
  font-size: 14px;
  line-height: 24px;
  text-align: start;
`

export interface CursorMessageProps {
  color?: string
  style?: React.CSSProperties
  message?: string
}

export default function CursorMessage({ message }: CursorMessageProps) {
  return <StyledText>{message}</StyledText>
}
