import { createClient } from '@liveblocks/client'
import { LiveblocksProvider } from '@liveblocks/react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import config from './config'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { setupProject } from './util'
// import {} from 'styled-components/cssprop'

const client = createClient({
  publicApiKey: config.liveblocksKey,
})

// if (true) {
ReactDOM.render(
  <React.StrictMode>
    <LiveblocksProvider client={client}>
      <App />
    </LiveblocksProvider>
  </React.StrictMode>,
  document.querySelector('#root')
)
// } else {
//   setupProject({
//     rootElement: (
//       <React.StrictMode>
//         <LiveblocksProvider client={client}>
//           <App />
//         </LiveblocksProvider>
//       </React.StrictMode>
//     ),
//     injectExtensionTo: 'body',
//     injectWebAppTo: '#root',
//   })
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
