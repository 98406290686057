import { useOthers } from '@liveblocks/react'
import React from 'react'
import Avatars from './Avatars'

export default function ConnectedUsers() {
  const others = useOthers().toArray()
  const users = React.useMemo(
    () => (others.length ? others.map(other => other?.presence?.user) : []),
    [others]
  )
  return <Avatars users={users} />
}
