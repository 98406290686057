import React from 'react'
import styled from 'styled-components/macro'
import { CursorMode } from './CursorPresence'

const common = `
  font-size: 14px;
  line-height: 24px;
  padding: 4px 12px; 
  max-width: 256px;
`

const StyledInput = styled.textarea`
  position: absolute;
  font-family: unset;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: none;
  border: none;
  color: #111;
  resize: none;
  &:focus {
    outline: none;
  }
  ${common}
`
const StyledSpan = styled.span`
  min-height: 24px;
  min-width: 256px;
  display: block;
  word-wrap: break-word;
  visibility: hidden;
  position: relative;
  color: red;
  text-align: start;
  ${common}
`

const charLimit = 280

export interface CursorInputProps {
  color?: string
  style?: React.CSSProperties
  message?: string
  onChange: (state: {
    message?: string
    mode?: CursorMode
    previousMessage?: string | null
  }) => void
}

export default function CursorInput({ color, style, onChange, message }: CursorInputProps) {
  return (
    <>
      <StyledSpan>{message}</StyledSpan>
      <StyledInput
        autoFocus
        maxLength={charLimit}
        spellCheck={false}
        placeholder='Say something..'
        value={message}
        rows={6}
        onChange={e => {
          onChange({
            mode: CursorMode.Chat,
            previousMessage: null,
            message: e.target.value,
          })
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onChange({
              mode: CursorMode.Chat,
              previousMessage: message,
              message: '',
            })
          } else if (e.key === 'Escape') {
            onChange({
              mode: CursorMode.Hidden,
            })
          }
        }}
        style={{ ...style }}
      />
    </>
  )
}
