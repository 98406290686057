import React from 'react'
import styled from 'styled-components/macro'

interface StyleProps {
  color?: string
  size: 'small' | 'default'
}

const AvatarDiv = styled.div<StyleProps>`
  height: ${props => (props.size === 'small' ? 32 : 32)}px;
  width: ${props => (props.size === 'small' ? 32 : 32)}px;
  border-radius: 100%;
  background: ${props => props.color ?? '#f4f5f7'};
  color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.15rem;
`

export interface AvatarProps {
  color: string
  emoji: string
  size?: 'small' | 'default'
  style?: React.CSSProperties
}

export default function Avatar({ color, emoji, style, size = 'default' }: AvatarProps) {
  return (
    <AvatarDiv style={{ ...style }} color={color} size={size}>
      {emoji}
    </AvatarDiv>
  )
}
