/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cognito_identity_pool_id": "us-west-1:484d4b34-a03e-4f0f-ad7f-c7175d1be91c",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_QPCZ92Ivp",
    "aws_user_pools_web_client_id": "68acp5skml8kl427t44qn4ik0k",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://7mbozisclvc4fnl6nlj6e5ktlm.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5uzjqaf6qzbxrifycsocvbxvza"
};


export default awsmobile;
