import React from 'react'
import { useUser } from '../common/UserContext'
import Row from '../ui/Row'
import ConnectedUsers from './ConnectedUsers'
import CurrentUser from './CurrentUser'

export default function Header() {
  const user = useUser()

  const username = user?.username
  return (
    <header className='App-header'>
      <object
        aria-label='wonder-web-logo'
        className='logo'
        data='./images/logo.svg'
        type='image/svg+xml'
      />
      <Row style={{ alignItems: 'center', gap: 16 }}>
        <ConnectedUsers />
        <div style={{ borderLeft: '1px solid #fafafa', height: '28px' }} />
        <CurrentUser />
      </Row>
    </header>
  )
}
